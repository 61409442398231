<template>
  <div class="registration_view">
    <a-form :form="form">
      <div class="main-label">Восстановление пароля</div>

      <a-form-item :validateStatus="statusPassword" :help="helpPassword">
        <a-input-password
          :value="form.password"
          v-on:input="updateFormPassword($event)"
          class="a-form-item-input"
          placeholder="Новый пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item
        :validateStatus="statusPasswordRepeat"
        :help="helpPasswordRepeat"
      >
        <a-input-password
          :value="form.password_confirmation"
          v-on:input="updateFormPasswordConfirmation($event)"
          class="a-form-item-input"
          placeholder="Повторите Новый пароль"
          size="large"
        />
      </a-form-item>

      <a-form-item>
        <div class="buttons">
          <a-button class="registration-button" @click="submitForm()">
            Восстановить
          </a-button>

          <a-button class="registration-button-active" @click="returnLogin()">
            Назад
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      statusPassword: 'success',
      helpPassword: '',
      statusPasswordRepeat: 'success',
      helpPasswordRepeat: '',
      form: {
        password: '',
        password_confirmation: '',
        email: '',
        token: ''
      }
    }
  },
  methods: {
    submitForm () {
      if (this.form.password === '' && this.form.password.length < 5) {
        this.statusPassword = 'error'
        this.helpPassword = 'Wrong Password!'
      }
      if (
        this.form.password_confirmation === '' ||
        this.form.password !== this.form.password_confirmation
      ) {
        this.statusPasswordRepeat = 'error'
        this.helpPasswordRepeat = 'Wrong Password Repeat!'
      }

      if (
        this.statusPassword === 'success' &&
        this.statusPasswordRepeat === 'success'
      ) {
        this.form.email = this.$route.query.email
        this.form.token = this.$route.query.token
        this.sendForm()
      }
    },

    updateFormPassword (e) {
      this.form.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    updateFormPasswordConfirmation (e) {
      this.form.password_confirmation = e.target.value
      this.statusPasswordRepeat = 'success'
      this.helpPasswordRepeat = ''
    },

    sendForm () {
      this.$http
          .put(process.env.VUE_APP_SERVER_URL + '/reset-password', this.form)
          .then((data) => {
          if (data.success) {
            this.userPersonalData = data.data.token
            this.$router.push('/login')
          } else {
            this.$notification.open({
              message: 'Ошибка входа в систему регистрации',
              description: data.message
            })
          }
        })
        .catch((error) => {
          let description = error.response.data.message
          Object.keys(error.response.data.data || {}).forEach((key) =>
            error.response.data.data[key].forEach((text) => {
              description += text + ', '
            })
          )

          this.$notification.open({
            message: 'Сетевая ошибка входа в систему',
            description
          })
        })
      // fetch(process.env.VUE_APP_SERVER_URL + '/api/reset-password', {
      //   method: 'PUT',
      //   mode: 'cors',
      //   cache: 'no-cache',
      //   credentials: 'same-origin',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   redirect: 'follow',
      //   referrerPolicy: 'no-referrer',
      //   body: JSON.stringify(this.form)
      // })
      //   .then((response) => {
      //     return response.json()
      //   })
      //   .then((data) => {
      //     if (data.success) {
      //       this.userPersonalData = data.data.token
      //       console.log(('userPersonalData', this.userPersonalData))
      //       this.$router.push('/login')
      //     } else {
      //       this.$notification.open({
      //         message: 'Ошибка входа в систему регистрации',
      //         description: data.message
      //       })
      //     }
      //   })
      //   .catch((error) => {
      //     let description = error.response.status + ' '
      //     Object.keys(error.response.data.errors || {}).forEach((key) =>
      //       error.response.data.errors[key].forEach((text) => {
      //         description += text + ', '
      //       })
      //     )
      //
      //     this.$notification.open({
      //       message: 'Сетевая ошибка входа в систему',
      //       description
      //     })
      //   })
    },
    returnLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
@import "../../assets/themes/layout.css";
.main-label {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 23px;
}

.a-form-item-input {
  height: 40px;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;

  /* justify-content: space-between; */
}

.registration-button {
  height: 40px;
  width: 100%;
  margin-right: 14px;

  color: white;
  background-color: #b12021;
}

.registration-button-active {
  height: 40px;
  width: 100%;

  background-color: white;
  color: #b12021;
}
</style>
